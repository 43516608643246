import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import { FORM_STATUSES, decodeURISafe } from '../../modules/utils';
import { DetailsPopup } from './DetailsPopup';
import { useFormik } from 'formik';
import { OrganizationModel, WebpageModel, WebpagePagesModel } from '../../modules/auth/models/OrganizationModel';
import { UserDbModel, UserModel } from '../../modules/auth/models/UserModel';
import { getUserByOrg, viewMyTesters } from '../../modules/auth/redux/AuthCRUD';
import { addWebsite, editWebsite, getWebsite, setWebsiteStatus, viewDepartment, viewWebpages } from '../../modules/auth/redux/OrganizationCRUD';
import clsx from 'clsx';
import { ErrorMsg } from '../layout/MasterLayout';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { cleanString } from '../utils';
import Select from 'react-select';

const schema = Yup.object().shape({
    url: Yup.lazy(value => {
        if (/((http([s]){0,1}:\/\/){0,1}(localhost|127.0.0.1){1}(([:]){0,1}[\0-9]{4}){0,1}\/{0,1}){1}/.test(value)) {
            return Yup.string().required('URL is required');
        }
        return Yup.string().required('URL is required').url('Invalid URL format').max(500);
    }),
    userId: Yup.string().nullable().optional().min(3, 'Select an assigned user'),
    name: Yup.string().required('Name is required'),
    departmentId: Yup.number()
        .test("orgTest", "Select an organization", function (value) {
            return value != null && value > 0;
        }),
})

const AddEditWebsite: React.FC<{ onSave: any }> = ({ onSave }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { action, websiteId }: any = useParams();
    const { orgId: orgIdFromUrl }: any = useParams();
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false)
    const divRef = useRef<HTMLInputElement>(null);
    const [isSearchDisplayed, setIsSearchDisplayed] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [orgData, setOrgData] = useState<Array<OrganizationModel>>();
    const [users, setUsers] = useState<Array<UserDbModel>>();
    const [data, setData] = useState<WebpagePagesModel>();
    const [dataError, setDataError] = useState<DataError>();
    const [managedByGigw, setManagedByGigw] = useState(false);
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const isAdmin = user.gamyata?.role == "ADMIN";
    const isSuperAdmin = user.gamyata?.role == "SUPERADMIN";
    const orgId = orgIdFromUrl ? orgIdFromUrl : undefined;
    const initialValues = { name: '', departmentId: orgId, userId: undefined, url: '' };
    let id: number | undefined = undefined;
    if (action == 'edit' && websiteId && !Number.isNaN(parseInt(websiteId))) {
        id = parseInt(websiteId);
    }
    const isActive = action == 'add' || action == 'edit' || action == 'webpages';

    const formik = useFormik<any>({
        initialValues,
        validationSchema: schema,
        onSubmit: (values, { setStatus }) => {
            setLoading(true);
            const assignedTo = values.userId == '' || values.userId == 'Assign Later' || !values.userId ? undefined : values.userId;
            const promise = id ? editWebsite(id, values.name, values.url, parseInt(values.departmentId), assignedTo) :
                addWebsite(values.name, values.url, parseInt(values.departmentId), assignedTo);
            promise.then(({ data: { id } }) => {
                setStatus({ state: FORM_STATUSES.SUCCESS, response: { id } });
                setShowPopup(true);
            }).catch((e) => {
                setLoading(false);
                const errorData = e?.response?.data;
                const lbErrorCode = errorData?.error?.code;
                const errorMsg = lbErrorCode == "ER_DUP_ENTRY" ? 'Duplicate Website! Cannot save website!' : 'Server Error! Cannot save website!';
                setStatus({ state: FORM_STATUSES.FAILURE, response: { error: errorMsg } });
            });
        },
    });

    const onToggle = (row: WebpageModel) => {
        if (!window.confirm(`Are you sure want to ${row.status == 'ACTIVE' ? 'deactivate' : 'activate'}?`)) {
            return;
        }
        const status = row.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
        setWebsiteStatus(row.id, status).then(() => {
            if (data) {
                setData({ website: data.website, pages: data.pages.map(x => x.id == row.id ? { ...x, status } : x) });
            }
        });
    }

    useEffect(() => {
        if (!isActive) {
            return;
        }
        setDataError(undefined);
        if (action == 'webpages') {
            viewWebpages(websiteId).then((r) => setData(r.data))
                .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            return;
        }
        formik.resetForm();
        viewDepartment().then((result) => setOrgData(result.data)).catch(e => setDataError({ error: "Server Error! Cannot fetch org data!" }));
        if (id) {
            getWebsite(id).then((result: AxiosResponse) => {
                formik.setFieldValue('name', result.data.name);
                formik.setFieldValue('url', result.data.url);
                formik.setFieldValue('departmentId', result.data.departmentId);
                formik.setFieldValue('userId', result.data.userId);
                //setManagedByGigw(result.data.managedByGigw);
                getUserByOrg(orgId, 'TESTER').then((result) => setUsers(result.data)).catch(e => setDataError({ error: "Server Error! Cannot fetch org data!" }));
            }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }));
        } else {
            viewMyTesters().then((result) => setUsers(result.data)).catch(e => setDataError({ error: "Server Error! Cannot fetch org data!" }));
        }
    }, [pathname]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setIsSearchDisplayed(false);
            }
        };

        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);

    const closePopup = () => history.push(`/v2/core/website/manage`);

    if (dataError) {
        return (
            <DetailsPopup key={'is: ' + isActive} title={'Error'} isActive={isActive} onCloseBtnClick={closePopup}>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0'
                    >
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </DetailsPopup>
        );
    }

    let title = ``;
    let dpClassName = '';
    let content;
    const orgOptions = orgData?.map(org => ({ value: org.id, label: cleanString(org.name) }));
    if (action == 'add' || action == 'edit') {
        title = `${_.startCase(action)} Website`;
        content = (
            <>
                <div className="row">
                    {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
                        <div className='alert-danger' style={{ padding: 5 }}>
                            <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
                        </div>
                    ) : null}
                    <div className="col-md-12">
                        <form onSubmit={formik.handleSubmit} noValidate className='test-form'>
                        {managedByGigw ? null : 
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label htmlFor='tb_url'>Enter URL</label>
                                    <input
                                        id='tb_url'
                                        type='text'
                                        placeholder='https://example.com'
                                        autoComplete='off'
                                        {...formik.getFieldProps('url')}
                                        className={clsx(
                                            {
                                                'is-invalid': formik.touched.url && formik.errors.url,
                                            },
                                            {
                                                'is-valid': formik.touched.url && !formik.errors.url,
                                            }
                                        )}
                                    />
                                    {formik.touched.url && formik.errors.url && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.url}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                                <div className='col-md-6'>
                                    <label>Website Name</label>
                                    <input
                                        type='text'
                                        placeholder='Enter name'
                                        {...formik.getFieldProps('name')}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.name}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div>}
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label htmlFor='s_user'>Assigned To</label>
                                    <select
                                        id='s_user'
                                        className='form-select form-select-solid form-select-lg'
                                        {...formik.getFieldProps('userId')}
                                    >
                                        <option value=''>Select User</option>
                                        {users?.map(user => (
                                            <option value={user.id}>{user.email}</option>
                                        ))}
                                        <option value='Assign Later'>Assign Later</option>
                                    </select>
                                    {formik.touched.userId && formik.errors.userId && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.userId}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div>
                            {managedByGigw ? <>
                                <div className='row'>
                                    <div className='col-md-12' style={{ paddingTop: 20 }}>
                                        <span>
                                            The following fields cannot be modified here. Please email your request to s3waas.support@nic.in to update it
                                        </span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <label>Website URL</label>
                                        <div>
                                            {formik.values.url}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <label>Website Name</label>
                                        <div>
                                            {formik.values.name}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <label>Organization Name</label>
                                        <div>
                                            {cleanString(orgData?.find(x => x.id == formik.values.departmentId)?.name)}
                                        </div>
                                    </div>
                                </div>
                            </> : null}
                            {managedByGigw ? null :
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <label htmlFor='s_org'>Organization</label>
                                        <Select
                                            id="s_org"
                                            className="form-select-lg"
                                            options={orgOptions}
                                            value={orgOptions?.find(option => option.value === formik.values.departmentId) || null}
                                            onChange={(selectedOption: any) => formik.setFieldValue("departmentId", selectedOption ? selectedOption.value : "")}
                                            placeholder="Select an organization..."
                                        />
                                        {formik.touched.departmentId && formik.errors.departmentId && (
                                            <ErrorMsg>
                                                <div className='fv-help-block'>{formik.errors.departmentId}</div>
                                            </ErrorMsg>
                                        )}
                                    </div>
                                </div>
                            }

                            <div className='row'>
                                <div className='col-md-12 testingmode1'>
                                    <button onClick={closePopup} type='button' className='btn cancel-form' disabled={loading}>
                                        Cancel
                                    </button>
                                    <button type='submit' className='btn btn-red' disabled={loading}>
                                        {!loading && 'Save'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    } else if (action == 'webpages') {
        title = `Manage Webpages`;
        dpClassName = 'manage-webpages-pop';
        const inactiveCnt = data?.pages.filter(w => w.status != 'ACTIVE').length || 0;
        const activeCnt = data ? data.pages.length - inactiveCnt : 0;
        content = (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div className="active-page-row">
                            <div tabIndex={0} className="page-search" onMouseDown={() => setIsSearchDisplayed(true)} onFocus={() => setIsSearchDisplayed(true)}>
                                <span><i className="fa fa-search" aria-hidden="true"></i></span>
                                <small> {search ? search : 'Search Page'}</small>
                            </div>
                            <div ref={divRef} className="full-width-searchbx" style={{ display: isSearchDisplayed ? 'block' : 'none' }}>
                                <div className="searchbx-wrapper">
                                    <div className="search-web-form">
                                        <span className="icon"><i className="fa fa-search"></i></span>
                                        <input type="search" id="search-page" placeholder="Search Website/Orgnization" value={search} onChange={(e) => setSearch(e.target.value)} onBlur={() => setIsSearchDisplayed(false)} />
                                        <a className="searchBox-clear js-clearSearchBox" onClick={() => setSearch('')}>clear search</a>
                                    </div>
                                </div>
                            </div>

                            <div className="active-page-box text-right">
                                <div className="active-page-counts">
                                    <div className="h2">{data?.pages.length}</div>
                                    <span className="active-page-text">Total Pages</span>
                                </div>
                                <div className="active-page-counts">
                                    <div className="h2 green">{activeCnt}</div>
                                    <span className="active-page-text">Active Pages</span>
                                </div>
                            </div>
                        </div>
                        <div className="project-list">
                            <table className="weblist-table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: "60%" }}>Webpage URL</th>
                                        <th scope="col">Page Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.pages.length == 0 ? 'Trigger at least one test for the application to crawl and populate the web pages' : null}
                                    {data?.pages.filter(x => x.url.includes(search)).map(row => (
                                        <tr>
                                            <td>{decodeURISafe(row.url)}</td>
                                            <td>{row.status == 'ACTIVE' ? 'ACTIVE' : 'INACTIVE'}</td>
                                            <td>
                                                <div className="check-box">
                                                    <input type="checkbox"
                                                        onChange={() => onToggle(row)}
                                                        checked={row.status == 'ACTIVE'} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <DetailsPopup key={'is: ' + isActive} title={title} isActive={isActive} onCloseBtnClick={closePopup} className={dpClassName} onSave={onSave} closePopup={closePopup} showPopup={showPopup} setShowPopup={setShowPopup} popupMessage={`Website is ${id ? 'updated' : 'created'} successfully`}>
                {content}
            </DetailsPopup>
        </>
    )
}

export { AddEditWebsite }
