import React from "react";
import { useLocation } from "react-router-dom";
import { Settings } from "../utils/config";
import { NonComplianceModel, WebsiteDashboardNc } from "./auth/models/TestRunModel";

function removeTrailingSlash(url: string) {
    return (url[url.length - 1] == "/") ? url.substr(0, url.length - 1) : url;
}

export function compareURLs(url1: string, url2: string) {
    return removeTrailingSlash(url1) == removeTrailingSlash(url2);
}

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function getUrlPrefix(isMobile: any, versionPrefix?: string) {
    return isMobile ? '/m' : `${versionPrefix ? versionPrefix : ''}`;
}

export const FORM_STATUSES = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
};

export function getUserLabel(role: string) {
    if (!role) {
        return 'User';
    }
    if (role.toUpperCase() == 'SUPERADMIN') {
        return "Super Admin";
    }
    if (role.toUpperCase() == 'ADMIN') {
        return Settings.AdminLabel;
    }
    if (role.toUpperCase() == 'TESTER') {
        return Settings.TesterLabel;
    }
}

export function isValidHttpUrl(str: string) {
    let url;
    try {
        url = new URL(str);
    } catch (_) {
        return false;  
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export function decodeURISafe(url: string) {
    let decodedUrl;
    try {
    decodedUrl = decodeURI(url);
    } catch (e) {
    decodedUrl = url;
    }
    return decodedUrl;
}

export function dashNctoNc(dashNc: WebsiteDashboardNc): NonComplianceModel {
    const {
        id,
        auditId,
        feId,
        status,
        priority,
        created,
        comments,
        failingElement,
        websiteId,
        feHash,
    } = dashNc;
    return {
        id,
        auditId,
        feId,
        status,
        priority,
        created,
        comments,
        failingElement,
        websiteId,
        feHash,
    };
}
