/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC, Suspense } from "react";
import { Redirect, Switch, Route, RouteProps, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../setup";
import { FallbackView } from "../../_monic/partials";
import { MasterLayout } from "../modulesv2/layout/MasterLayout";
import { ManageWebsite } from "../modulesv2/websites/ManageWebsite";
import { ManageTestRuns } from "../modulesv2/test/ManageTestRuns";
import { WebsiteHeatmap } from "../modulesv2/websites/WebsiteHeatmap";
import { ManageTestSchedules } from "../modulesv2/test/ManageTestSchedules";
import { RunTest } from "../modulesv2/test/RunTest";
import { ManageOrganization } from "../modulesv2/org/ManageOrganization";
import { ManageUsers } from "../modulesv2/org/ManageUsers";
import { ManageOnboarding } from "../modulesv2/org/ManageOnboarding";
import { LoadPdf } from "../pages/landing/LoadPdf";
import { ViewActivityLogs } from "../modulesv2/core/ViewActivityLogs";
import { Settings } from "../modulesv2/core/Settings";
import { ViewProfile } from "../modulesv2/core/ViewProfile";
import AuthInit from "../modulesv2/core/AuthInit";
import { ManageApiKey } from "../modulesv2/org/ManageApiKey";
import { OnboardingPage } from "../modulesv2/org/OnboardingPage";
import { Dashboard } from "../modulesv2/test/Dashboard";
import { UserModel } from "../modules/auth/models/UserModel";
import { ViewTestSchedule } from "../modulesv2/test/ViewTestSchedule";
import { ViewTestResult } from "../modulesv2/test/ViewTestResult";
import ScrollToTop from "../modulesv2/layout/ScrollToTop";
import { ManageTest } from "../modulesv2/websites/ManageTest";
import { ViewTestRunsHistory } from "../modulesv2/test/ViewTestRunsHistory";

const RouteV2: FC<RouteProps<string> & { componentProps?: any, disableSidebar?: boolean }> = (props) => {
  const { component, disableSidebar, componentProps, ...mProps } = props;
  if (component == undefined) {
    return null;
  }
  const Component: React.ElementType = component;
  return (
    <Route
      {...mProps}
      render={() => (
        <MasterLayout disableSidebar={disableSidebar}>
          <Component {...componentProps} />
        </MasterLayout>
      )}
    />
  );
};

const RoutesV2: FC = () => {
  const history = useHistory();
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const isAuthorized = user && user.gamyata;
  const inOnboarding = user && !user.gamyata;
  return (
    <Suspense fallback={<FallbackView />}>
      <ScrollToTop>
      <AuthInit>
      <Switch>
        {inOnboarding ? <RouteV2
              path="/v2/onboarding"
              component={OnboardingPage}
              disableSidebar={true}
            /> : null}
        {!isAuthorized ? (
          /*Redirect to home when user is not authorized*/
            <Route
            render={() => {
              window.location.href = '/';
              return null;
            }}
          />
        ) : (
          <Switch>
            <RouteV2 path="/v2/dashboard" component={Dashboard} />
            <RouteV2 path="/v2/core/website/manage/:action/:websiteId" component={ManageWebsite} />
            <RouteV2 path="/v2/core/website/manage/:action" component={ManageWebsite} />
            <RouteV2 path="/v2/core/website/manage" component={ManageWebsite} />
            <RouteV2 path="/v2/core/testruns/:pageNo" component={ViewTestRunsHistory} />
            <RouteV2 path="/v2/core/testruns" component={ViewTestRunsHistory} />
            <RouteV2 path="/v2/core/website/:websiteId/testruns/:pageNo" component={ManageTestRuns} />
            <RouteV2 path="/v2/core/website/:websiteId/testruns" component={ManageTestRuns} />
            <RouteV2 path="/v2/core/test/:testRunId/:activeTab/:testInstanceId/:auditId" component={ViewTestResult} />
            <RouteV2 path="/v2/core/test/:testRunId/:activeTab/:testInstanceId" component={ViewTestResult} />
            <RouteV2 path="/v2/core/test/:testRunId/:activeTab" component={ViewTestResult} />
            <RouteV2 path="/v2/core/test/:testRunId/" component={ViewTestResult} />
            <RouteV2
              path="/v2/activities/reports/mywebsites/heatmap"
              component={WebsiteHeatmap}
            />
            <RouteV2
              path="/v2/activities/testmgmt/schedulereport/:testScheduleId/:pageNo"
              component={ViewTestSchedule}
            />
            <RouteV2
              path="/v2/activities/testmgmt/schedulereport/:testScheduleId"
              component={ViewTestSchedule}
            />
            <RouteV2
              path="/v2/activities/testmgmt/schedules/:action/:testScheduleId"
              component={ManageTestSchedules}
            />
            <RouteV2
              path="/v2/activities/testmgmt/schedules/:action"
              component={ManageTestSchedules}
            />
            <RouteV2
              path="/v2/activities/testmgmt/schedules"
              component={ManageTestSchedules}
            />
            <RouteV2
              path="/v2/activities/testmgmt/schedules-by-website/:websiteId/:action/:testScheduleId"
              component={ManageTestSchedules}
            />
            <RouteV2
              path="/v2/activities/testmgmt/schedules-by-website/:websiteId/:action"
              component={ManageTestSchedules}
            />
            <RouteV2
              path="/v2/activities/testmgmt/schedules-by-website/:websiteId"
              component={ManageTestSchedules}
            />
            <RouteV2
              path="/v2/activities/testmgmt/schedules-by-website"
              component={ManageTest}
            />
            <RouteV2
              path="/v2/activities/testmgmt/runtest"
              component={RunTest}
            />
            <RouteV2 path='/v2/core/users/downloadonboarding/:id' component={LoadPdf} />
            <RouteV2
              path="/v2/core/users/view"
              component={ViewProfile}
            />
            <RouteV2
              path="/v2/core/users/:role/:action/:userId"
              component={ManageUsers}
            />
            <RouteV2
              path="/v2/core/users/:role/:action"
              component={ManageUsers}
            />
            <RouteV2
              path="/v2/core/users/:role"
              component={ManageUsers}
            />
            <RouteV2
              path="/v2/core/org/:orgId/users/:role/:action/:userId"
              component={ManageUsers}
            />
            <RouteV2
              path="/v2/core/org/:orgId/users/:role/:action"
              component={ManageUsers}
            />
            <RouteV2
              path="/v2/core/org/:orgId/users/:role"
              component={ManageUsers}
            />
            <RouteV2
              path="/v2/core/org/:action/:orgId"
              component={ManageOrganization}
            />
            <RouteV2
              path="/v2/core/org/:action"
              component={ManageOrganization}
            />
            <RouteV2
              path="/v2/core/onboarding/:action/:userId"
              component={ManageOnboarding}
            />
            <RouteV2
              path="/v2/core/onboarding/:pageNo"
              component={ManageOnboarding}
            />
            <RouteV2
              path="/v2/core/onboarding/manage"
              component={ManageOnboarding}
            />
            <RouteV2
              path="/v2/core/activitylogs/:pageNo"
              component={ViewActivityLogs}
            />
            <RouteV2
              path="/v2/core/activitylogs"
              component={ViewActivityLogs}
            />
            <RouteV2
              path="/v2/core/settings"
              component={Settings}
            />
            <RouteV2
              path="/v2/core/apikeys/:action"
              component={ManageApiKey}
            />

            {/* Redirects */}
            <Redirect from="/v2/core/website" to="/v2/core/website/manage" />
            {/* <Redirect from="/v2/activities/testmgmt/schedules" to="/v2/activities/testmgmt/schedules/manage" /> */}
            <Redirect to='/v2/core/website/manage' />
          </Switch>
        )}
      </Switch>
      </AuthInit>
      </ScrollToTop>
    </Suspense>
  );
};

export { RoutesV2 };
