import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import { getTestRunsByWebsiteId, getTestRunsWithFilter } from '../../modules/auth/redux/TestRunCRUD';
import { TestRunByWebsite, TestRunModel } from '../../modules/auth/models/TestRunModel';
import _ from 'lodash';
import Pagination from '../layout/Pagination';
import { SortedTh } from '../layout/MasterLayout';
import { cleanString } from '../utils';
import { getApiKeys } from '../../modules/auth/redux/AuthCRUD';
import { ApiKeyModel } from '../../modules/auth/models/AuthModel';

const getScoreColor = (score: number) => {
    if (score > 89) {
        return 'green';
    } else if (score > 39) {
        return 'orange';
    }
    return 'red';
}

const ViewTestRunsHistory: React.FC = () => {
    const { pageNo = 1 }: any = useParams();
    const history = useHistory();
    const [data, setData] = useState<TestRunModel[]>();
    const [apiData, setApiData] = useState<Array<ApiKeyModel>>();
    const [isLoading, setIsLoading] = useState(false);
    const [dataError, setDataError] = useState<{ error: string }>();
    const [testMode, setTestModeFilter] = useState<string>('-1');
    const [wcagVersion, setWcagVersionFilter] = useState<string>('-1');
    const [wcagLevel, setWcagLevelFilter] = useState<string>('-1');
    const [status, setStatusFilter] = useState<string | undefined>('-1');
    const [apiFilter, setApiFilter] = useState<string | undefined>('-1');
    const [sortColumn, setSortColumn] = useState<string | undefined>('created');
    const [sortDirection, setSortDirection] = useState<string | undefined>('desc');
    const limit = 10;
    let pgNo = parseInt(pageNo);
    if (pgNo < 1 || Number.isNaN(pgNo)) {
        pgNo = 1
    }

    const fetchData = () => {
        const where: any = {};
        for (const whereCnd of [{ testMode }, { wcagVersion }, { wcagLevel }, { status }]) {
            const key = Object.keys(whereCnd)[0];
            const value = (whereCnd as any)[key];
            if (value && value != '-1') {
                where[key] = value;
            }
        }
        if (apiFilter && apiFilter != '-1') {
            where.apiKeyId = apiFilter;
        }
        const filter: any = { where, limit: limit + 1, skip: (pgNo - 1) * limit, include: ['user', 'apiKey', {
            relation: 'website',
            scope: {
              include: [{relation: 'department'}],
            },
          }] };
        if (sortDirection) {
            filter.order = `${sortColumn} ${sortDirection.toUpperCase()}`;
        }
        setIsLoading(true);
        getTestRunsWithFilter(filter).then((result) => {
            setData(result.data);
            setDataError(undefined);
        })
            .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
    }

    const fetchApiKeys = () => {
        setIsLoading(true);
        getApiKeys().then((result) => setApiData(result.data))
            .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
    }

    useEffect(fetchApiKeys, []);

    useEffect(() => {
        return history.listen((location) => {
            setIsLoading(true);
        })
    }, [history]);

    useEffect(() => {
        fetchData();
    }, [pgNo, sortDirection]);

    if (isLoading) {
        return (
            <div>
                <br />
                <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </div>
        );
    }

    if (dataError || data == null) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className='test-runsfor'>
            <div className="bredcrumb-cnntr">
                <ul>
                    <li><Link to={`/v2/dashboard`}>Home</Link></li>
                    <li>View Tests History</li>
                </ul>
            </div>
            <div className="site-head">
                <h1 className="main-title">Test History</h1>
            </div>
            <div className="project-list table-filter">
                <form>
                    <select className="single-page"
                        aria-label='select test mode'
                        defaultValue={testMode}
                        onChange={e => setTestModeFilter(e.target.value)}>
                        <option value='-1'>All Test Modes</option>
                        <option value='single_page'>Single Page</option>
                        <option value='full_site'>Full Site</option>
                    </select>
                    <select className="wcag2"
                        aria-label='select WCAG version'
                        defaultValue={wcagVersion}
                        onChange={e => setWcagVersionFilter(e.target.value)}>
                        <option value='-1'>All WCAG Versions</option>
                        <option value='WCAG 2.0 without ARIA'>WCAG 2.0 without ARIA</option>
                        <option value='WCAG 2.1 without ARIA'>WCAG 2.1 without ARIA</option>
                        <option value='WCAG 2.0'>WCAG 2.0</option>
                        <option value='WCAG 2.1'>WCAG 2.1</option>
                    </select>
                    <select className="wcag-level"
                        aria-label='select WCAG level'
                        defaultValue={wcagLevel}
                        onChange={e => setWcagLevelFilter(e.target.value)}>
                        <option value='-1'>All WCAG Levels</option>
                        <option value='Level A'>Level A</option>
                        <option value='Level AA'>Level AA</option>
                        <option value='Level AAA'>Level AAA</option>
                    </select>
                    <select className="partially-comp"
                        aria-label='select status'
                        defaultValue={status}
                        onChange={e => setStatusFilter(e.target.value)}>
                        <option value='-1'>All Statuses</option>
                        <option value='PENDING'>PENDING</option>
                        <option value='RUNNING'>RUNNING</option>
                        <option value='PARTIALLY COMPLETE'>PARTIALLY COMPLETE</option>
                        <option value='COMPLETE'>COMPLETE</option>
                        <option value='FAILURE'>FAILURE</option>
                        <option value='CANCELLED'>CANCELLED</option>
                    </select>
                    <select className="partially-comp"
                        aria-label='select api service'
                        defaultValue={apiFilter}
                        onChange={e => setApiFilter(e.target.value)}>
                        <option value='-1'>Any</option>
                        {apiData?.map(api => <option value={api.id}>{api.serviceName}</option>)}
                    </select>
                    <button className="btn" onClick={(e) => { fetchData(); e.preventDefault(); }}>Filter</button>
                </form>
            </div>
            <div className="project-list">
                <table className="weblist-table dataTable">
                    <thead>
                        <tr>
                            <th scope="col" className="single-page">Department</th>
                            <th scope="col">Website</th>
                            <th scope="col">Test Settings</th>
                            <SortedTh fieldName={'created'}
                                sortColumn={sortColumn}
                                setSortColumn={setSortColumn}
                                sortDirection={sortDirection}
                                setSortDirection={setSortDirection}>Run Date/Time</SortedTh>
                            <SortedTh fieldName={'status'}
                                sortColumn={sortColumn}
                                setSortColumn={setSortColumn}
                                sortDirection={sortDirection}
                                setSortDirection={setSortDirection}>Status</SortedTh>
                            <SortedTh fieldName={'score'}
                                sortColumn={sortColumn}
                                setSortColumn={setSortColumn}
                                sortDirection={sortDirection}
                                setSortDirection={setSortDirection}>Score</SortedTh>
                            <th scope="col">Initiated By</th>
                            <th scope="col" className="action">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(0, limit).map(run => {
                            const created = run.created ? moment(run.created).tz('Asia/Kolkata') : null;
                            return (
                                <tr>
                                    <td>{run.website?.department?.name}</td>
                                    <td>{run.baseUrl}</td>
                                    <td>
                                        {_.startCase(run.testMode)} <br />
                                        {run.wcagVersion} <br />{run.wcagLevel}
                                    </td>
                                    <td>{created ? <><strong>{created.format('DD MMM YYYY')}</strong><br />{created.format('h:mm:ss A')}</> : null}</td>
                                    <td>
                                        {run.status == 'FAILURE' ? <img src="/assetsv2/images/Failure.svg" alt="Delete" className="del-icon" /> : null}
                                        {run.status == 'RUNNING' ? <img src="/assetsv2/images/running.svg" alt="Delete" className="del-icon" /> : null}
                                        {run.status == 'PENDING' ? <img src="/assetsv2/images/pending.svg" alt="Delete" className="del-icon" /> : null}
                                        {run.status == 'COMPLETE' ? <img src="/assetsv2/images/Success.svg" alt="Success" /> : null}
                                        {run.status == 'PARTIALLY COMPLETE' ? <img src="/assetsv2/images/Success.svg" alt="Success" /> : null}
                                        {/* TODO: other run status icons*/}
                                        <span> {run.status}</span>
                                    </td>
                                    <td>{run.score ? <strong className={getScoreColor(run.score)}>{run.score}</strong> : null}</td>
                                    <td>
                                        {run.apiKey ? <span>{run.apiKey.serviceName}</span> : null}
                                        {run.user ? <span>{run.user.parichay?.fullName || run.user?.email}</span> : null}
                                    </td>
                                    <td className="view-box">
                                        <Link to={`/v2/core/test/${run.id}/overview`} className="btn">View Test Report</Link>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {data.length == 0 ? <div style={{ textAlign: 'center', marginTop: 5 }}><strong>No data to display</strong></div> : null}
            <nav aria-label="Page navigation" className="pagination-wrap">
                {data ?
                    <Pagination
                        currentPage={pgNo}
                        totalCount={data.length}
                        pageSize={limit}
                        infinite={true}
                        onPageChange={(page: number) => history.push(`/v2/core/testruns/${page}`)}
                    /> : null}
            </nav>
        </div>
    )
}

export { ViewTestRunsHistory }
