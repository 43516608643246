import React, { useEffect, useState } from 'react';
import {FocusTrap} from 'focus-trap-react';
import { Popup } from '../layout/Popup';

type Props = {
    isActive: boolean
    title: string
    children?: any
    titleChildren?: any
    onCloseBtnClick?: any
    className?: string
    masterClassName?: string
    onSave?: any
    closePopup?: any
    popupMessage?: string
    showPopup?: any
    setShowPopup?: any
}

const DetailsPopup: React.FC<Props> = ({ isActive, title, children, titleChildren, onCloseBtnClick, className, masterClassName, onSave, closePopup, popupMessage, showPopup, setShowPopup }) => {
    useEffect(() => {
        const handleActiveClass = (show: boolean) => {
            if (show) {
                $('.details-main')?.addClass('active');
                $('.big-contain')?.addClass('show-details-pop');
            } else {
                $('.details-main')?.removeClass('active');
                $('.big-contain')?.removeClass('show-details-pop');
                $('.details-main')?.removeClass('full-view');
                $('.full-viewlink')?.show();
            }
        }
        handleActiveClass(isActive);
        return () => {
            handleActiveClass(false);
        };
    }, [isActive]);

    const content = (
        <div>
            <section id='details-popup' className={`main-container white_bg details-main ${masterClassName ? masterClassName : 'add-test-form'} ${className || ''}`}>
                <div className="container-fluid">
                    <div className="row inner-container">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="test-runsfor">
                                <div className="row">
                                    <div className="site-head">
                                        <div className="col-md-6">
                                            <h2 className="main-title">{title}</h2>
                                            {titleChildren}
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <div className="text_icon">
                                                <a href="#" onClick={() => {
                                                    $('.details-main').removeClass('active');
                                                    $('.details-main').removeClass('full-view');
                                                    $('.main-container-outer').removeClass('show-details-pop');
                                                    $('.full-viewlink').show();
                                                    if (onCloseBtnClick) {
                                                        onCloseBtnClick();
                                                    }
                                                }} className="close-error-dtls-pop" title="Close details"><img src="/assetsv2/images/cross.png" alt="cross link" /></a>
                                            </div>
                                            {masterClassName ? <div className="text_result">
                                                <a href="#" className="full-viewlink" onClick={() => {
                                                    $('.details-main').addClass('full-view');
                                                    $('.full-viewlink').hide();
                                                }}><img src="/assetsv2/images/arrow.png" aria-hidden="true" />Open in full screen</a>
                                            </div> : null}
                                        </div>
                                    </div>
                                </div>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {setShowPopup ? <Popup onCloseBtnClick={() => { onSave(); closePopup(); }} show={showPopup} setShow={setShowPopup}>{popupMessage}</Popup> : null}
        </div>
    );
    return (
        <div>
            {isActive ? (
                <>
                    <FocusTrap active={isActive} focusTrapOptions={{initialFocus: false, fallbackFocus: () => '#details-popup'}} >
                        <div>
                            {content}
                        </div>
                    </FocusTrap>
                </>
            ) : (
                content
            )}
        </div>
    );
}

export { DetailsPopup }
