import React, {useEffect, useState} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import * as Yup from 'yup'
import { createOnboardingRequest, getOnboardingRequest } from '../../modules/auth/redux/AuthCRUD'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { RootState } from '../../../setup'
import { UserDbModel, UserModel } from '../../modules/auth/models/UserModel'
import * as auth from '../../modules/auth/redux/AuthRedux'
import { Settings } from '../../utils/config'
import { BreadcrumbTitle, LandingHeader } from './Header'
import { LandingFooter } from './Footer'
import { useFormik } from 'formik'
import { FORM_STATUSES } from '../../modules/utils'

const OnboardingRequest: React.FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [loading, setLoading] = useState(false);
  const [enableUpload, setEnableUpload] = useState(true);
  const [file, setFile] = useState(null);
  const [userData, setUserData] = useState<UserModel>();
  const dispatch = useDispatch();
  useEffect(() => {
    getOnboardingRequest().then((result) => {
      setUserData(result.data);
      dispatch(auth.actions.login(result.data));
    }).catch(e => {});
  }, []);

  const validationSchema = Yup.object().shape({
    agreement: Yup.mixed()
    .nullable()
    .notRequired()
    .test("FILE_SIZE", "Uploaded file is too big.", 
        value => !value || (value && value.size <= 2000000))
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", 
        value => !value || (value && ['application/pdf'].includes(value.type)))
  })
  const initialValues = {name: ''};
  const formik = useFormik<any>({
    initialValues,
    validationSchema,
    onSubmit: (values, {setStatus}) => {
      setLoading(true);
      const email = user.email;
      const parichay = {
        fullName: user.fullName,
        designation: user.designation,
        departmentName: user.departmentName,
        email: user.email,
        userName: user.userName,
        mobileNo: user.mobileNo,
      }
      createOnboardingRequest(email, parichay, file, enableUpload).then(() => {
        getOnboardingRequest().then((result) => {
          setUserData(result.data);
          setLoading(false);
        }).catch(e => {});
      }).catch(e => {
        console.error(e);
        setLoading(false);
        setStatus({state: FORM_STATUSES.FAILURE, response: {error: 'Server Error!'}});
      });
    },
  })

  if (user == null) {
    dispatch(auth.actions.logout());
    return (
      <Switch>
        <Redirect to='/' />
      </Switch>
    )
  }

  if (userData?.status == 'ACTIVE') {
    return (
      <Switch>
        <Redirect to='/dashboard' />
      </Switch>
    )
  }
  
  const onFileChange = (e: any) => {
    setFile(e.target.files[0])
    formik.setFieldValue('agreement', e.currentTarget.files[0]);
  };

  return (
    <div className="main-body-content">
    <LandingHeader />
    <div id="main-content">
      <BreadcrumbTitle>Onboarding Request</BreadcrumbTitle>
    <div className='card mx-10 my-10 mb-xl-10'>
    { userData ? (
    <p className='heading3 mx-6'>
      Your onboarding request has been submitted and is pending review
    </p>
    ) : null }
    {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
        <div className='mb-lg-15 alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
        </div>
      ) : null}
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

              <div className='col-lg-8 fv-row'>
                {user.fullName}
              </div>
            </div>
            
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

              <div className='col-lg-8 fv-row'>
                {user.email}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>

              <div className='col-lg-8 fv-row'>
                {Settings.AdminLabel}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Department</label>

              <div className='col-lg-8 fv-row'>
                {user.departmentName}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Designation</label>

              <div className='col-lg-8 fv-row'>
                {user.designation}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Mobile No</label>

              <div className='col-lg-8 fv-row'>
                {user.mobileNo}
              </div>
            </div>

            <div className='row mb-6'>
              <input
                type='radio'
                className='form-check-input'
                name='enableUpload'
                onChange={(e) => setEnableUpload(e.target.value === 'true')}
                value='true'
                id='cb_true'
              />
              <label htmlFor='cb_true'>
                <span className='text-gray-800 fw-bold mx-3'>Onboarding Approval PDF</span>
              </label>
              <input
                type='radio'
                name='enableUpload'
                className='form-check-input'
                onChange={(e) => setEnableUpload(e.target.value === 'true')}
                value='false'
                id='cb_false'
              />
              <label htmlFor='cb_false'>
                <span className='text-gray-800 fw-bold mx-3'>Onboarding Approval Email</span>
              </label>
            </div>
            
            {enableUpload ? (
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Upload Authorization Letter</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='file'
                  name='agreement'
                  className='form-control form-control-lg'
                  placeholder='Upload File'
                  // {...formik.getFieldProps('agreement')}
                  onChange={onFileChange}
                  disabled={userData != null} />
                  {formik.touched.agreement && formik.errors.agreement && ( 
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.agreement}</div>
                  </div>
               )} 
              </div>
            </div>
            ) : null}

          </div>

          { userData ? null : (
          <div className='card-footer d-flex py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading || false}>
              {!loading && 'Submit Onboarding Request'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>)}
        </form>
      </div>
      </div>
      <LandingFooter />
      </div>
  )
}

export {OnboardingRequest}
