import axios from 'axios'
import { ActivityLogs, ConsolidatedNCReport, FailuresCount, GetTestRunsWhere, NonComplianceModel, TestInstanceModel, TestRunModel, TestScheduleModel, Website, WebsiteDashboard, WebsiteDashboardNc } from '../models/TestRunModel'
import { isValidHttpUrl } from '../../utils'
import { API_URL } from './constants'

export const CATEGORIES_URL = `${API_URL}/audit-categories`
export const TAGS_URL = `${API_URL}/audit-tags`;
export const TEST_REPORTING_DETAILS_URL = `${API_URL}/test-reporting-details`;
export const TEST_RUNS_BY_WEBSITE_URL = `${API_URL}/test-runs-by-website`
export const TEST_INSTANCES_URL = `${API_URL}/test-instances`
export const TEST_INSTANCES_WITH_NC_URL = `${API_URL}/test-instances-with-nc`
export const FAILURES_LIST_URL = `${API_URL}/failures-list/`;
export const NC_LIST_BY_AUDIT_URL = `${API_URL}/nc-list-by-audit/`;
export const TEST_RUNS_URL = `${API_URL}/test-runs`
export const TEST_SCHEDULES_URL = `${API_URL}/test-schedules`
export const TEST_SCHEDULES_WITH_WEBSITE_SEARCH_URL = `${API_URL}/test-schedules-with-website-search`
export const PATCH_TEST_SCHEDULES_URL = `${API_URL}/patch_test-schedules`
export const DELETE_TEST_SCHEDULES_URL = `${API_URL}/put_test-schedules`
export const TEST_SCHEDULE_REPORT_URL = `${API_URL}/test-schedule-report`
export const NON_COMPLIANCES_URL = `${API_URL}/non-compliances`
export const PATCH_NON_COMPLIANCES_URL = `${API_URL}/patch-non-compliances`
export const PATCH_NON_COMPLIANCES_BULK_URL = `${API_URL}/patch-non-compliances-bulk`
export const OUTSTANDING_NON_COMPLIANCES_URL = `${API_URL}/outstanding-non-compliances`
export const ACTIVITY_LOGS_URL = `${API_URL}/activity-log`
export const ISSUE_STATS_URL = `${API_URL}/issue-stats`
export const WEBSITES_URL = `${API_URL}/websites`
export const SCREENSHOTS_URL = `${API_URL}/test-instances/screenshots/`
export const WEBSITE_DASHBOARD_URL = `${API_URL}/website-dashboard`
export const HISTORICAL_DASHBOARD_URL = `${API_URL}/historical-dashboard`
export const EMAIL_URL = `${API_URL}/email`
export const CANCEL_TEST_URL = `${API_URL}/cancel_test`;

let categoryCache: any;
let tagsCache: any;
let testReportingDetailsCache: TestReportingDetails;

const TEST_RUNS_STATUSES = {
  PENDING: "PENDING",
  RUNNING: "RUNNING",
  COMPLETE: "COMPLETE",
  PARTIALLY_COMPLETE: "PARTIALLY COMPLETE",
  FAILURE: "FAILURE",
};

const TEST_SCHEDULES_STATUSES = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  COMPLETE: "COMPLETE",
};

export function runTest(name: string, baseUrl: string, testSettings: any, websiteId: number, testScheduleId?: number) {
  if (!isValidHttpUrl(baseUrl)) {
    return Promise.reject(new Error("Server Error: Invalid URL"));
  }
  const {testMode, wcagVersion, wcagLevel, disableExperimental} = testSettings;
  return axios.post(TEST_RUNS_URL, {name, baseUrl, status: TEST_RUNS_STATUSES.PENDING, 
    testMode, wcagVersion, wcagLevel, disableExperimental, testSettings, websiteId, testScheduleId});
}

export function addTestSchedule(name: string, baseUrl: string, testSettings: any, cron: string, startTimeEpoch: string, websiteId: number) {
  if (!isValidHttpUrl(baseUrl)) {
    return Promise.reject(new Error("Server Error: Invalid URL"));
  }
  return axios.post(TEST_SCHEDULES_URL, {name, baseUrl, status: TEST_SCHEDULES_STATUSES.ACTIVE, testSettings, cron, startTimeEpoch, websiteId});
}

export function editTestSchedule(id: number, name: string, baseUrl: string, testSettings: any, cron: string, startTimeEpoch: string, websiteId: number) {
  if (!isValidHttpUrl(baseUrl)) {
    return Promise.reject(new Error("Server Error: Invalid URL"));
  }
  return axios.post(PATCH_TEST_SCHEDULES_URL + '/' + id, {name, baseUrl, status: TEST_SCHEDULES_STATUSES.ACTIVE, testSettings, cron, startTimeEpoch, websiteId});
}

export function deleteTestSchedule(id: number) {
  return axios.post(DELETE_TEST_SCHEDULES_URL + '/' + id + '/deactivate');
}

export function getTestScheduleReport(id: number) {
  return axios.get(TEST_SCHEDULE_REPORT_URL + '/' + id);
}

export function getTestRunsByWebsiteId(id: number, filter: any) {
  return axios.get(TEST_RUNS_BY_WEBSITE_URL + '/' + id, {params: {filter}});
}

export function getTestRunsWithFilter(filter: any) {
  return axios.get<Array<TestRunModel>>(TEST_RUNS_URL, {params: {filter}});
}

export function getTestRuns(where?: GetTestRunsWhere, order?: any, limit?: number, skip?: any) {
  return axios.get<Array<TestRunModel>>(TEST_RUNS_URL, {params: {filter: {where, order, skip, include: ['website'], limit}}});
}

export function getWebsiteDashboard(where?: GetTestRunsWhere) {
  return axios.get<WebsiteDashboard>(WEBSITE_DASHBOARD_URL, {params: {filter: {where}}});
}

export function getHistoricalDashboard(id: number, filter?: any) {
  return axios.get<WebsiteDashboard>(HISTORICAL_DASHBOARD_URL + '/' + id, {params: {filter}});
}

export function getTestSchedules(filter?: any) {
  return axios.get<Array<TestScheduleModel>>(TEST_SCHEDULES_URL, {params: {filter}});
}

export function getTestSchedulesWithSearch(filter?: any, websiteSearchKeyword?: string) {
  return axios.get<Array<TestScheduleModel>>(TEST_SCHEDULES_WITH_WEBSITE_SEARCH_URL, {params: {filter, websiteSearchKeyword}});
}

export function getNonCompliances(testInstanceId: number) {
  return axios.get<Array<NonComplianceModel>>(NON_COMPLIANCES_URL, {params: {filter: {where: testInstanceId}}});
}

export function getOutstandingNonCompliances(where?: GetTestRunsWhere) {
  return axios.get<Array<WebsiteDashboardNc>>(OUTSTANDING_NON_COMPLIANCES_URL, {params: {filter: {where}}});
}

export function getFailuresList(testRunId: number) {
  return axios.get<Array<FailuresCount>>(`${FAILURES_LIST_URL}/${testRunId}`, {});
}

export function getNCListByAudit(testRunId: number, auditId: string, filter?: any) {
  return axios.get<ConsolidatedNCReport>(`${NC_LIST_BY_AUDIT_URL}/${testRunId}/${auditId}`, {params: {filter}});
}

export function getTestSchedule(id: number) {
  return axios.get<Array<TestScheduleModel>>(`${TEST_SCHEDULES_URL}/${id}`);
}

export function getWebsites(where?: {}, order?: string[]) {
  return axios.get<Array<Website>>(WEBSITES_URL, {params: {filter: {where, order, include: [{relation: "department"}]}}});
}

export function getTestRun(id: number) {
  const params: any = {
    filter: {
      include: [
        {
          relation: "testInstances",
          scope: {
            order: "id asc",
            fields: {id: true, url: true, status: true, testRunId: true, created: true, cntTotalIssues: true, stats: true}
          }
        },
        {
          relation: "website",
          scope: {
            order: "id asc",
            fields: {id: true, url: true, status: true, testRunId: true, created: true, cntTotalIssues: true, stats: true, websiteId: true, name: true}
          }
        }
      ]
    }
  };
  return axios.get<TestRunModel>(`${TEST_RUNS_URL}/${id}`, {params});
}

export function getScreenshots(id: string) {
  return axios.get<any>(`${SCREENSHOTS_URL}/${id}`);
}

export async function getCategories() {
  if (!categoryCache) {
    const results = await axios.get<Array<{id: number, name: string}>>(`${CATEGORIES_URL}`);
    categoryCache = results.data.map(x => x.name);
  }
  return categoryCache;
}

export async function getTags() {
  if (!tagsCache) {
    const results = await axios.get<Array<{id: number, name: string}>>(`${TAGS_URL}`);
    tagsCache = results.data.map(x => x.name);
  }
  return tagsCache;
}

export async function getTestReportingDetails() {
  if (!testReportingDetailsCache) {
    const results = await axios.get<Array<TestReportingDetail>>(`${TEST_REPORTING_DETAILS_URL}`);
    testReportingDetailsCache = results.data.reduce((map: any, obj) => (map[obj.identifier] = obj, map), {});
  }
  return testReportingDetailsCache;
}

export function getTestInstanceWithNC(id: number) {
  return axios.get<TestInstanceModel>(`${TEST_INSTANCES_WITH_NC_URL}/${id}`, {});
}

export function getTestInstanceForMediaInventory(id: number) {
  const params: any = {
    filter: {
      fields: ["id", "url", "status", "mediaInventory"],       
    }
  };
  return axios.get<TestInstanceModel>(`${TEST_INSTANCES_URL}/${id}`, {params});
}

export function getTestInstancesForMediaInventory(ids: Array<number>) {
  const params: any = {
    filter: {
      fields: ["id", "url", "status", "mediaInventory"],       
      where: {"or": ids.map(id => { return {id}; })},
    }
  };
  return axios.get<TestInstanceModel>(`${TEST_INSTANCES_URL}`, {params});
}

export function cancelTestRun(testRunId: number) {
  return axios.post(`${CANCEL_TEST_URL}/${testRunId}`);
}

export function patchNc(nonCompliance: {id: number, comments?: string, status?: string, priority?: string}) {
  return axios.post(`${PATCH_NON_COMPLIANCES_URL}/${nonCompliance.id}`, nonCompliance);
}

export function patchNcBulk(nonCompliance: {comments?: string, status?: string, priority?: string}, websiteId: number, auditId: string, idList?: number[]) {
  return axios.post(`${PATCH_NON_COMPLIANCES_BULK_URL}`, {nc: nonCompliance, websiteId, auditId, idList});
}

export function retryTestInstance(id: number) { 
  return axios.post(`${TEST_INSTANCES_URL}/retry-failed-instance/${id}`);
}

export function getActivityLogs(filter?: {direction?: 'prev' | 'next', lastCreated?: string, lastId?: string, limit: number}) {
  return axios.get<Array<ActivityLogs>>(ACTIVITY_LOGS_URL, {params: {filter}}); 
}

export function email(to: string, subject: string, html: string) {
  return axios.post(EMAIL_URL, {to, subject, html});
}

export function getTop5Score() {
  return axios.get<Array<Website>>(WEBSITES_URL + '/top5Score'); 
}

export function getBottom5Score() {
  return axios.get<Array<Website>>(WEBSITES_URL + '/bottom5Score'); 
}

export function getAccessibilityStatus() {
  return axios.get<{compliant: number, nonCompliant: number, untested: number}>(WEBSITES_URL + '/accessibilityStatus'); 
}

export function getScoreTrendMonthly() {
  return axios.get<Array<{year: number, month: number, avgScore: number}>>(WEBSITES_URL + '/scoreTrendMonthly'); 
}

export function getTopIssuesByOrg() {
  return axios.get<Array<{title: string, count: number}>>(WEBSITES_URL + '/topIssuesByOrg'); 
}

export function getIssueStats(filter?: any) {
  return axios.get<Array<TestRunModel>>(ISSUE_STATS_URL, {params: {filter}}); 
}
