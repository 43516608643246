import { FC, MouseEventHandler } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../setup";
import { UserModel } from "../../modules/auth/models/UserModel";
import { Link } from "react-router-dom";
import { Settings } from "../../utils/config";

export const Header: FC<{ onClick: MouseEventHandler<HTMLElement>, disableSidebar?: boolean }> = ({ onClick, disableSidebar }) => {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const isSuperAdmin = user.gamyata && user.gamyata.role == 'SUPERADMIN';
    const name = user.gamyata?.givenName ? user.gamyata.givenName : user.fullName;
    return (
        <header className="header-section" onClick={onClick}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-custom">
                        <div className="site-logo text-center">
                                <Link to="/v2/dashboard" aria-label={Settings.GamyataLabel + " Logo"} title="Go to home" rel="home">    
                                {Settings.isGamyata ?
                                    <img src="/assets/images/gamyata-logo.png" alt={Settings.GamyataLabel + " Logo"} />
                                    : <img src="/assetsv2/images/SugamyaWeb-logo.svg" alt="SugamyaWeb logo" /> }
                                </Link>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-6 col-xs-12 clearfix col-content">
                    <div className="disclaim-box pull-left">
                        <div className="disclaimer-head">Disclaimer:</div>
                        <div className="disclaim-text">
                        <div className="marquee">
                            <div className="marqee-div">
                                <span>SugamyaWeb performs automated tests across websites, adhering to and covering most pointers outlined by the Web Content Accessibility Guidelines (WCAG). However, to achieve 100% accessibility, users are advised to perform additional manual tests.Automated tools, while comprehensive, may not capture all accessibility issues, and manual testing ensures a thorough and complete evaluation.</span>
                            </div>     
                        </div>
                        </div>
                    </div>
                
                    {disableSidebar ? null :
                        <>
                            
                                <div className="header-right pull-right">
                                    <div className="name-n-role">
                                        <span>{name}</span><br />
                                        <span className="role">{user.gamyata?.role}</span>
                                    </div>
                                    <div className="user-login dropdown">
                                        <span className="prof-img">
                                            <span className="loged-in-user">{name.charAt(0).toUpperCase()}</span>
                                            <span className="status green"></span> {/*<!-- user class "red" for offline -->*/}
                                            <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><em className="fa fa-angle-down"></em></a>
                                            <ul className="dropdown-menu">
                                                <li><Link to="/v2/core/users/view">My Profile</Link></li>
                                                {isSuperAdmin ? <li><a href="/dashboard">Use the old interface</a></li> : null}
                                                <li><a href="/logout">Log Out</a></li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                            
                            <div id="menutoggle-btn" onClick={() => {
                                $('.sidebar').toggleClass('sidebar-show');
                                $('.fa-bars').toggleClass('fa-times');
                            }}>
                                <i className="fa fa-bars" aria-hidden="true" />
                            </div>
                        </>}
                        </div>
                </div>
            </div>
        </header>
    );
}
