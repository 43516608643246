import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { RootState } from '../../../setup';
import { FORM_STATUSES } from '../../modules/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as auth from '../../modules/auth/redux/AuthRedux';
import Select from "react-select";
import { ErrorMsg } from '../layout/MasterLayout';
import { UserModel } from '../../modules/auth/models/UserModel';
import { getOnboardingRequest, createOnboardingRequest } from '../../modules/auth/redux/AuthCRUD';
import { Settings } from '../../utils/config';
import { Popup } from '../layout/Popup';
import { viewDepartmentForOnboarding } from '../../modules/auth/redux/OrganizationCRUD';
import { OrganizationModel } from '../../modules/auth/models/OrganizationModel';
import { cleanString } from '../utils';


const OnboardingPage: React.FC = () => {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [orgData, setOrgData] = useState<Array<OrganizationModel>>();
    const [dataError, setDataError] = useState<DataError>();
    const [enableUpload, setEnableUpload] = useState(true);
    const [file, setFile] = useState<any>(null);
    const [userData, setUserData] = useState<UserModel>();
    const dispatch = useDispatch();
    useEffect(() => {
        viewDepartmentForOnboarding().then((result) => setOrgData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch org data!"}));
        getOnboardingRequest().then((result) => {
            setUserData(result.data);
            dispatch(auth.actions.login(result.data));
        }).catch(e => { });
    }, []);

    const validationSchema = Yup.object().shape({
        departmentId: Yup.number()
            .required('Select an organization'),
        agreement: Yup.mixed()
            .test('required', 'Authorization letter pdf is missing', (value) => {
                return enableUpload ? value != null : true;
            })
            .test("FILE_SIZE", "Selected file is too big",
                value => enableUpload ? value && value.size <= 2000000 : true)
            .test("FILE_FORMAT", "Selected file is not a pdf",
                value => enableUpload ? value && ['application/pdf'].includes(value.type) : true)
    })
    const initialValues = { agreement: '', departmentId: undefined };
    const formik = useFormik<any>({
        initialValues,
        validationSchema,
        onSubmit: (values, { setStatus }) => {
            setLoading(true);            
            const email = user.email;
            const parichay = {
                fullName: user.fullName,
                designation: user.designation,
                departmentName: user.departmentName,
                email: user.email,
                userName: user.userName,
                mobileNo: user.mobileNo,
            }
            createOnboardingRequest(email, parichay, file, enableUpload, values.departmentId).then(() => {
                getOnboardingRequest().then((result) => {
                    setUserData(result.data);
                    setLoading(false);
                    setSuccess(true);
                }).catch(e => { });
            }).catch(e => {
                console.error(e);
                setLoading(false);
                setStatus({ state: FORM_STATUSES.FAILURE, response: { error: 'Server Error!' } });
            });
        },
    })

    if (user == null) {
        dispatch(auth.actions.logout());
        return (
            <Switch>
                <Redirect to='/' />
            </Switch>
        )
    }

    if (userData?.status == 'ACTIVE') {
        return (
            <Switch>
                <Redirect to='/dashboard' />
            </Switch>
        )
    }

    const onFileChange = (e: any) => {
        setFile(e.target.files[0])
        formik.setFieldValue('agreement', e.currentTarget.files[0]);
    };

    const css = `
        body {
            font-family: sans-serif;
        }
        .onboard-request {
            background: #f3f2ef;
            padding: 10px 0;
            margin: -8px;
        }
        .request-box {
            width: 800px;
            margin: 0 auto;
            margin-bottom: 50px;
        }
        .request-box h2 {
            margin-bottom: 40px;
        }
        .form-box {
            padding: 30px;
            background: #fff;
            box-shadow: 0px 0px 20px 0px #8c8c8c;
            position: relative;
        }
        .form-box h3 {
            margin: 0px 0px 30px 0px;
            text-transform: uppercase;
            font-weight: 600;
        }
        .form-group label {
            color: #000;
            margin: 30px 0px 20px;
            display: block;
            font-weight: bold;
            font-size: 14px;
        }
        .form-group .form-input {
            /* height: 50px; */
            background: #fff;
            font-size: 14px;
            color: #000;
            font-weight: 400;
            border-radius: 5px;
            border: 1px solid #2e2e2e;
            display: block;
            width: -webkit-fill-available;
            padding: 15px 15px;
        }
        .files-hide input {
            height: 0;
            overflow: hidden;
            width: 0;
            display: none;
        }
        .file-type[type=file]+label {
            background: #f3f3f3;
            border-radius: 5px;
            color: #000;
            cursor: pointer;
            display: inline-block;
            font-weight: 600;
            padding: 16px 30px;
            border: 1px solid #e8e6e6;
            margin: 0px 0px 20px;
        }

        @media only screen and (max-width: 600px) {
            .request-box{
                width: calc(100% - 30px);
            }
        }
    `;

    const orgOptions = orgData?.map(org => ({ value: org.id, label: cleanString(org.name) }));

    return (
        <>
            <style>{css}</style>
            <section className="onboard-request">
                <div className="request-box">
                    <h2>Onboarding Request</h2>
                    <div className="form-box">
                        <h3>Details</h3>
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className="form-group">
                                <label htmlFor="Name">Name</label>
                                {user.fullName}
                            </div>
                            <div className="form-group">
                                <label htmlFor="Email">Email ID</label>
                                {user.email}
                            </div>
                            <div className="form-group">
                                <label htmlFor="Role">Role</label>
                                {Settings.AdminLabel}
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="Department">Department</label>
                                {user.departmentName}
                            </div> */}
                            <div className="form-group">
                                <label htmlFor="Designation">Designation</label>
                                {user.designation}
                            </div>
                            <div className="form-group">
                                <label htmlFor="Mobile number">Mobile number</label>
                                {user.mobileNo}
                            </div>
                            <div className='form-group'>
                                <label htmlFor='s_org'>Organization</label>
                                <Select
                                    id="s_org"
                                    className="form-select-lg"
                                    options={orgOptions}
                                    value={orgOptions?.find(option => option.value === formik.values.departmentId) || null}
                                    onChange={(selectedOption: any) => formik.setFieldValue("departmentId", selectedOption ? selectedOption.value : "")}
                                    placeholder="Select an organization..."
                                />
                                {formik.touched.departmentId && formik.errors.departmentId && (
                                    <ErrorMsg>
                                        <div className='fv-help-block'>{formik.errors.departmentId}</div>
                                    </ErrorMsg>
                                )}
                            </div>
                            {/* <div>
                            <input
                                type='radio'
                                className='form-check-input'
                                name='enableUpload'
                                checked={enableUpload}
                                onChange={(e) => setEnableUpload(e.target.checked)}
                                value='true'
                                id='cb_true'
                            />
                            <label htmlFor="enableUpload" style={{marginLeft: 5}}>Authorization Letter PDF</label>
                            <br />
                            <input
                                type='radio'
                                name='enableUpload'
                                className='form-check-input'
                                checked={!enableUpload}
                                onChange={(e) => setEnableUpload(!e.target.checked)}
                                value='false'
                                id='cb_false'
                            />
                            <label htmlFor="enableUpload" style={{marginLeft: 5}}>Onboarding Approval Email</label>
                            </div> */}
                            {enableUpload ? (
                            <div className="form-group">
                                <label htmlFor="file">Upload Authorization Letter PDF <a href="/data/sugamya-consent-letter.docx">(Sample letter template)</a></label>
                                <div className="files-hide">
                                    <input id="file"
                                        type='file'
                                        name='agreement'
                                        className='file-type'
                                        placeholder='Upload File'
                                        // {...formik.getFieldProps('agreement')}
                                        onChange={onFileChange}
                                        disabled={userData != null}
                                    />
                                    <label htmlFor="file">Choose file</label>
                                    <span style={{ marginLeft: 10 }}>{file?.name}</span>
                                    {formik.touched.agreement && formik.errors.agreement && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.agreement}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div>) : null}
                            <div className="button-box">
                                {userData ? <h2>
                                    Your onboarding request has been submitted and is pending review
                                </h2> : (
                                    <div className='card-footer d-flex py-6 px-9'>
                                        <button type='submit' className='btn btn-primary' disabled={loading || false}>
                                            {!loading && 'Submit Onboarding Request'}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>)}
                            </div>
                            <Popup show={success} setShow={() => { }}>Your onboarding request has been submitted and is pending review</Popup>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export { OnboardingPage }
